import {Box, Button, Heading, Text} from "grommet";
import {useState} from "react";
import algosdk from "algosdk";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import Countdown from 'react-countdown';
import {PeraWalletConnect} from "@perawallet/connect";


function Raffle(raffle) {
    const [value, setValue] = useState(1);
    const algodClient = new algosdk.Algodv2("", 'https://mainnet-api.algonode.network', '');

    async function enterRaffle() {
        try {
            if (raffle.wallet === 'AGASHPTZ6FQP7T6MIA5CNRTLKGFZPVHSEO7D7PCGQ3THYVNJYVB2BIQWW4') {
                toast.error('Connect your wallet to enter raffle!')
            } else {
                raffle.loadingSet(true)
                if (raffle.props.walletEntries === raffle.props.walletLimit) {
                    toast.error('You already have the maximum numbers of entries for this raffle!')
                    raffle.loadingSet(false)
                } else if ((+raffle.props.walletEntries + +value) > raffle.props.walletLimit) {
                    toast.error('You need to choose a lower number of tickets! You will go over the wallet limit!')
                    raffle.loadingSet(false)
                } else if ((+value + +raffle.props.entries.length) > raffle.props.ticketLimit) {
                    toast.error('You need to choose a lower number of tickets. Not enough tickets available!')
                    raffle.loadingSet(false)
                } else if (new Date() > new Date(raffle.props.endingAt) ) {
                    toast.error('Raffle is over! Wait for the draw!')
                    raffle.loadingSet(false)
                } else {
                    const params = await algodClient.getTransactionParams().do();
                    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                        suggestedParams: {
                            ...params
                        },
                        from: raffle.wallet,
                        to: 'MNGOGMU3TUA7D6HJ5VCP5GK3RTCUHLFV66JOE7VKQIHTSE5YZSFDASZNCI',
                        assetIndex: 360019122,
                        amount: value * raffle.props.price,
                    })
                    const txnsArray = [txn];
                    algosdk.assignGroupID(txnsArray)
                    const peraWallet = new PeraWalletConnect();
                    await peraWallet.reconnectSession()
                    const txnGroup = [{
                        txn: txnsArray[0],
                        signers: raffle.wallet
                    }]
                    const signedTxns = await peraWallet.signTransaction([txnGroup])
                   const blobs = signedTxns.map((txn) => {
                        return btoa(String.fromCharCode(...txn))
                    })
                    await axios.post(`${process.env.REACT_APP_API_BASE}/raffles/${raffle.props.id}`, {
                        raffleId: raffle.props.id,
                        wallet: raffle.wallet,
                        txId: txn.txID().toString(),
                        signedTxns: blobs,
                    })
                    toast(`Tickets Purchased!`)
                    raffle.loadingSet(false)
                }
            }
        } catch (e) {
            console.log(e)
            toast.error('Error Entering Raffle! Contact Admin')
            raffle.loadingSet(false)
        }

    }

    async function claimPrize() {
        try {
            if (raffle.props.claimFee === 0) {
                raffle.loadingSet(true)
                const params = await algodClient.getTransactionParams().do();
                const optInTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                    suggestedParams: {
                        ...params
                    },
                    from: raffle.wallet,
                    to: raffle.wallet,
                    assetIndex: raffle.props.assetId,
                    amount: 0
                })

                const txnsArray = [optInTxn];
                algosdk.assignGroupID(txnsArray)
                const peraWallet = new PeraWalletConnect();
                await peraWallet.reconnectSession()
                const txnGroup = [{
                    txn: txnsArray[0],
                    signers: raffle.wallet
                }]
                const signedTxns = await peraWallet.signTransaction([txnGroup])
                await algodClient.sendRawTransaction(signedTxns).do()
                await algosdk.waitForConfirmation(algodClient, optInTxn.txID().toString(), 4)
                await axios.post(`${process.env.REACT_APP_API_BASE}/raffles/${raffle.props.id}/claim`, {
                    raffleId: raffle.props.id,
                    txId: optInTxn.txID().toString()
                })
                toast(`Prize Claimed!`)
                raffle.loadingSet(false)
            } else {
                raffle.loadingSet(true)
                const params = await algodClient.getTransactionParams().do();
                const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                    suggestedParams: {
                        ...params
                    },
                    from: raffle.wallet,
                    to: 'MNGOGMU3TUA7D6HJ5VCP5GK3RTCUHLFV66JOE7VKQIHTSE5YZSFDASZNCI',
                    amount: algosdk.algosToMicroalgos(raffle.props.claimFee)
                })
                const optInTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                    suggestedParams: {
                        ...params
                    },
                    from: raffle.wallet,
                    to: raffle.wallet,
                    assetIndex: raffle.props.assetId,
                    amount: 0
                })
                const txnsArray = [optInTxn, txn];
                algosdk.assignGroupID(txnsArray)
                const peraWallet = new PeraWalletConnect();
                await peraWallet.reconnectSession()
                const txnGroup = [{
                    txn: txnsArray[0],
                    signers: raffle.wallet
                }, {
                    txn: txnsArray[1],
                    signers: raffle.wallet
                }]
                const signedTxns = await peraWallet.signTransaction([txnGroup])
                await algodClient.sendRawTransaction(signedTxns).do()
                await algosdk.waitForConfirmation(algodClient, txn.txID().toString(), 4)
                await axios.post(`${process.env.REACT_APP_API_BASE}/raffles/${raffle.props.id}/claim`, {
                    raffleId: raffle.props.id,
                    txId: txn.txID().toString()
                })
                toast(`Prize Claimed!`)
                raffle.loadingSet(false)
            }
        } catch (e) {
            console.log(e)
            toast.error('Error Entering Raffle! Contact Admin')
            raffle.loadingSet(false)
        }
    }

    const renderer = ({days, hours, minutes, seconds}) => {
        // Render a countdown
        return <Text size={"small"}><b>{days} days {hours}hrs {minutes} mins </b></Text>;
    }

    const button = (active, entries, limit, winner, claimed) => {
        if (active === true && (entries === limit || entries > limit)) {
            return <Box alignSelf={"center"} alignContent={"center"} pad={"xsmall"} width={"100%"} round={"small"}
                        margin={"small"} background={"linear-gradient(0deg, #7b00b7 0%, #ba7bfa 100%);"}>
                <Box alignSelf={"center"}><Text weight={"bold"} size={"medium"} color={"white"}>SOLD
                    OUT</Text></Box>
            </Box>
        } else if (active === true) {
            return <Box justify={"between"} direction={"row"} margin={"small"} width={"100%"}>
                <Box alignSelf={"center"} width={"xxsmall"} margin={"none"}>
                    <input
                        className="py-1.5 w-20 rounded-xl bg-white text-center text-black font-bold"
                        type="text"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        min={0}
                        max={raffle.props.walletLimit}
                        onChange={(e) => {
                            setValue(e.target.value)
                        }}
                        value={value}
                        style={{
                            height: "32px",
                            width: "47px",
                            borderRadius: "0.75rem",
                            fontFamily: "inherit",
                            textAlign: "center",
                            lineHeight: "24px",
                            fontWeight: 700,
                            fontSize: "16px",
                        }}
                    />
                </Box>
                <Box round={"small"} alignSelf={"center"}
                     width={"220px"} pad={"xsmall"}
                     margin={"none"} alignContent={"center"}
                     background={"linear-gradient(rgb(0, 223, 179), rgb(55, 195, 167));"}
                >
                    <Button alignSelf={"center"} color={"white"} onClick={enterRaffle}><Text weight={"bold"}
                                                                                             size={"medium"}>Buy Raffle
                        Ticket</Text></Button>
                </Box>
                <Box/></Box>
        } else if (active === false && winner === raffle.wallet && claimed === false) {
            return <Box alignSelf={"center"} alignContent={"center"} pad={"xsmall"} width={"100%"} round={"small"}
                        margin={"small"} background={"linear-gradient( #FCB0D4, #FFD04D);"}>
                <Button alignSelf={"center"} color={"white"} onClick={claimPrize}><Text weight={"bold"}
                                                                                        size={"medium"}>CLAIM
                    PRIZE</Text></Button>
            </Box>
        } else if (active === false) {
            return <Box alignSelf={"center"} alignContent={"center"} pad={"xsmall"} width={"100%"} round={"small"}
                        margin={"small"} background={"linear-gradient( #969496, #545454);"}>
                <Text weight={"bold"} size={"medium"} color={"white"} alignSelf={"center"}>RAFFLE
                    ENDED!</Text>
            </Box>
        }
    }

    return (

        <Box>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Box align="center" pad="small" key={raffle.props.assetId}
                 round="medium" margin="small"
                 direction="column" alignSelf="center"
                 background={"white"}
                 width={"315px"} height={"540px"}
            >
                <Box alignContent={"start"} justify={"start"} alignSelf={"start"} margin={"none"} pad={"none"}
                     style={{marginTop: "-8px", cursor: "pointer"}}><Heading color={"#8F8D8F"} id={"hoverID"}
                                                                             margin={"none"} size={"18px"}
                                                                             onClick={() => {
                                                                                 window.open(`https://www.nftexplorer.app/asset/${raffle.props.assetId}`);
                                                                             }}>ASA
                    ID: {raffle.props.assetId}</Heading></Box>
                <Box align="center" justify="center"
                     background={{
                         "color": "light-3",
                         "image": `url('https://www.randswap.com/v1/nft-url/${raffle.props.assetId}')`}} round="xsmall" height={"300px"} width={"300px"}
                     style={(raffle.props.winner !== raffle.wallet && raffle.props.active === false) || raffle.props.prizeClaimed === true ? {filter: "grayscale(100%)"} : null}
                />
                {button(raffle.props.active, raffle.props.entries, raffle.props.ticketLimit, raffle.props.winner, raffle.props.prizeClaimed)}
                <Box width={"100%"} pad={"small"} margin={"none"} round={"medium"}
                     background={"#EFE4EF"}>
                    <Box justify={"between"} direction={"row"} width={"100%"} margin={"none"}>
                        <Box><Text color={"#8F8D8F"} weight={"bold"} size={"small"}>Raffle Draw:</Text></Box>
                        {raffle.props.active === true ?
                            <Box><Countdown
                                date={raffle.props.endingAt}
                                renderer={renderer}
                            />
                            </Box> :
                            <Text weight={"bold"}
                                  size={"small"}>Winner: {raffle.props.winner.substring(0, 5)}....</Text>
                        }
                    </Box>
                    <Box justify={"between"} direction={"row"} width={"100%"} margin={"none"}>
                        <Box><Text color={"#8F8D8F"} weight={"bold"} size={"small"}>Ticket Cost:</Text></Box>
                        <Box><Text weight={"bold"} size={"small"}>{raffle.props.price} 🍤</Text></Box>
                    </Box>
                    <Box justify={"between"} direction={"row"} width={"100%"} margin={"none"}>
                        <Box><Text color={"#8F8D8F"} weight={"bold"} size={"small"}>Max Entries Per Wallet:</Text></Box>
                        <Box><Text size={"small"} weight={"bold"}>{raffle.props.walletLimit}</Text></Box>
                    </Box>
                    <Box justify={"between"} direction={"row"} width={"100%"} margin={"none"}>
                        <Box><Text color={"#8F8D8F"} weight={"bold"} size={"small"}>Tickets Remaining:</Text></Box>
                        { raffle.props.entries > raffle.props.ticketLimit ?
                            <Box><Text size={"small"}
                                       weight={"bold"}>0 / {raffle.props.ticketLimit}</Text></Box> :
                            <Box><Text size={"small"}
                                       weight={"bold"}>{raffle.props.ticketLimit - raffle.props.entries} / {raffle.props.ticketLimit}</Text></Box>
                        }

                    </Box>
                    <Box justify={"between"} direction={"row"} width={"100%"} margin={"none"}>
                        <Box><Text color={"#8F8D8F"} weight={"bold"} size={"small"}>Claim Fee (10% Floor Price):</Text></Box>
                        <Box><Text size={"small"}
                                   weight={"bold"}>{raffle.props.claimFee === 0 ? "🆓" : raffle.props.claimFee + "A"}</Text></Box>
                    </Box>
                    <Box justify={"between"} direction={"row"} width={"100%"} height={"25px"} margin={"none"}>
                        <Box><Text color={"#8F8D8F"} weight={"bold"} size={"small"}>Your Entries:</Text></Box>
                        <Box alignSelf={"end"}><Text size={"small"}
                                                     weight={"bold"}>{raffle.props.walletEntries} 🎟️</Text></Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}

export default Raffle
