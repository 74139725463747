import MyAlgoConnect from "@randlabs/myalgo-connect";
import React from "react";
import { Box, Button, Header, Heading, Image } from "grommet";
import Raffles from "./Raffles";
import raffleGif from "./raffle.gif";
import { PeraWalletConnect } from "@perawallet/connect";

class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.setShrimpBalance = this.setShrimpBalance.bind(this);
    this.state = {
      accounts: "",
      shrimpBalance: null,
      accountClicked: false,
      key: 0,
    };
  }

  setShrimpBalance(amount) {
    this.setState({
      shrimpBalance: amount,
    });
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <Box flex={true}>
        <Header
          alignContent={"center"}
          alignSelf={"center"}
          margin={"small"}
          flex={false}
          onClick={() => {
            this.setState({ accountClicked: false });
          }}
          id={"hoverID"}
          pad={{ horizontal: "small" }}
          responsive={true}
        >
          <Heading size={"xlarge"} id={"mainHeading"}>
            SHRIMPY
          </Heading>
          <Box alignContent={"center"} alignSelf={"center"} width={"small"}>
            <Image src={raffleGif} />
          </Box>
          <Heading size={"xlarge"} id={"mainHeading"}>
            RAFFLES
          </Heading>
        </Header>
        <Box
          direction={"row"}
          alignSelf={"center"}
          width={"xlarge"}
          pad={{ horizontal: "medium" }}
          flex={true}
          justify="between"
          responsive={true}
        >
          <Heading
            size={"30px"}
            alignSelf={"start"}
            margin={"small"}
            color={"#BA7BFA"}
          >
            🍤 BALANCE:{" "}
            <span style={{ color: "#8600FC" }}>
              {this.state.shrimpBalance === null
                ? ""
                : this.state.shrimpBalance}{" "}
            </span>
          </Heading>
          {localStorage.getItem("pera") ? (
            <Heading
              size={"30px"}
              alignSelf={"end"}
              margin={"small"}
              color={"#BA7BFA"}
              id={"hoverID"}
              onClick={() => {
                if (this.state.accountClicked === false) {
                  this.setState({ accountClicked: true });
                } else {
                  this.setState({ accountClicked: false });
                }
              }}
            >
              ACCOUNT
            </Heading>
          ) : (
            <div
              onClick={async () => {
                const peraWallet = new PeraWalletConnect();
                const accounts = await peraWallet.connect();
                localStorage.setItem("pera", accounts[0]);
                this.setState({ accountClicked: false });
                this.setState({ key: Math.random() });
              }}
            >
              <Heading
                size={"30px"}
                alignSelf={"end"}
                margin={"small"}
                color={"#BA7BFA"}
                id={"hoverID"}
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  msUserSelect: "none",
                  msTouchSelect: "none",
                }}
              >
                CONNECT
              </Heading>
            </div>
          )}
        </Box>
        {this.state.accountClicked === true ? (
          <Box
            alignSelf={"center"}
            pad={"medium"}
            background={"white"}
            round={"medium"}
          >
            Address: {localStorage.getItem("pera")}
            <Heading
              size={"30px"}
              color={"#BA7BFA"}
              alignSelf={"end"}
              margin={"none"}
              style={{ marginBottom: "-20px", cursor: "pointer" }}
              onClick={async () => {
                localStorage.removeItem("pera");
                const peraWallet = new PeraWalletConnect();
                await peraWallet.disconnect();
                this.setState({ pera: "" });
                this.setState({ accountClicked: false });
                this.setState({ key: Math.random() });
              }}
              id={"hoverID"}
            >
              DISCONNECT
            </Heading>
          </Box>
        ) : (
          <Box key={this.state.key}>
            {this.state.accountClicked === false &&
            localStorage.getItem("pera") ? (
              <Raffles
                wallet={localStorage.getItem("pera")}
                shrimpBalance={this.setShrimpBalance}
              />
            ) : (
              <Raffles
                wallet={
                  "AGASHPTZ6FQP7T6MIA5CNRTLKGFZPVHSEO7D7PCGQ3THYVNJYVB2BIQWW4"
                }
                shrimpBalance={this.setShrimpBalance}
              />
            )}
          </Box>
        )}
      </Box>
    );
  }
}

export default Wallet;
