import {useEffect, useState} from "react";
import {Box, Heading, Spinner} from "grommet";
import Raffle from "./Raffle";

function Raffles(wallet) {
    function getRaffles() {
        return fetch(`${process.env.REACT_APP_API_BASE}/raffles/${wallet.wallet}`).then(data => data.json())
    }

    const [raffles, setRaffles] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        let mounted = true;
        getRaffles()
            .then(results => {
                if (mounted) {
                    setRaffles(results)
                    wallet.shrimpBalance(results.shrimp)
                }
            })
        return () => mounted = false;
    }, [loading])

    return (
        <Box>
            {loading === false ?
                <Box>
                    {raffles.length === 0 ?
                        <Box>
                            <Heading alignSelf={"center"}>Loading Raffles</Heading>
                        </Box> :
                        <Box alignSelf={"center"}  alignContent={"center"}>
                            <Box alignContent={"center"} alignSelf={"center"} justify={"start"} >
                                <Box align="center" pad="small" height="large" width={"xxlarge"}
                                     flex={true} fill="vertical" alignContent={"center"} alignSelf={"center"}
                                     direction={"row"} wrap justify={"center"}>
                                    {raffles.raffles.map((raffle) =>
                                        (<Raffle props={raffle} key={raffle.assetId} wallet={wallet.wallet}
                                                 loadingSet={setLoading}/>))}
                                </Box>
                            </Box>
                        </Box>
                    } </Box> : <Box align="center" justify="center" pad="large">
                    <Spinner size='xlarge'/>
                </Box>}
        </Box>
    );
}

export default Raffles;
