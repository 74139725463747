import {
  Box, Footer,
  Grommet, Image
} from 'grommet';
import Wallet from "./components/Wallet";

const theme = {
  global: {
    font: {
      family: 'Jost',
      size: '18px',
      height: '20px',
    },
  },
  heading: {
    color: {
      dark: '#8600FC',
      light: '#8600FC'
    },
    font: {
      family: 'Koulen',
      size: '50px',

    }
  },

  tip: {
    content: {
      background: 'black'
    },
    drop: {
      color: 'black',
    }
  }
};

function App() {
  return (
       <Grommet theme={theme} full={true} flex={true} background={"linear-gradient(0deg, #ffeefd 0%, #fffbc7 100%);"}>
        <Box style={{marginBottom: "60px"}}>
          <Wallet/>
        </Box>
         <Footer background="white" pad="medium" id={"footer"}>
           <Box>
              SHRIMPY RAFFLES
           </Box>
           <Box direction={"row"} wrap={false}>
              <Image src={'discord.png'} style={{width: "24px", height: "24px", marginLeft: '8px' ,marginRight: '8px'}}
                onClick={() => {
                  window.open(`https://discord.gg/sbbd7yg3Ce`);
                }
              }
              />
              <Image src={'twitter.png'} style={{width: "24px", height: "24px", marginLeft: '8px' ,marginRight: '8px'}}
                onClick={() => {
                  window.open(`https://twitter.com/yieldling`);
                }}
              />
           </Box>
         </Footer>
      </Grommet>
  );
}

export default App;
